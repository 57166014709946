import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "install-dependencies-for-react-for-datavisualization"
    }}>{`Install dependencies for React for Datavisualization`}</h1>
    <Vimeo id={429501405} mdxType="Vimeo" />
    <p>{`There are a couple of libraries we're going to use often in this book: D3,
Topojson, and Lodash. We're using D3 to do our heavy lifting, Topojson to parse
geographical data, and Lodash to make data manipulation easier.`}</p>
    <p>{`You can install them like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-{caption=\"Install",
        "metastring": "dependencies\"}",
        "dependencies\"}": true
      }}>{`$ npm install --save d3 topojson lodash
`}</code></pre>
    <p>{`Additionally, we're using Bootstrap for default styling and String for string
manipulation. You should install them as well.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-{caption=\"Styling",
        "metastring": "and string manipulation\"}",
        "and": true,
        "string": true,
        "manipulation\"}": true
      }}>{`$ npm install --save bootstrap string
`}</code></pre>
    <p>{`Now your environment is ready! You should see a default React App page in the
browser without errors. Keep `}<inlineCode parentName="p">{`npm start`}</inlineCode>{` running as you follow the examples in
this book.`}</p>
    <p>{`Some examples are going to need other libraries. When that happens, I'll remind
you to install them.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      